import { Component, HostListener, OnInit } from '@angular/core';
import { MenuItem } from 'src/app/types/menu';

import { ThemeLoaderService } from 'src/app/services/theme-loader.service';
import { UserService } from 'src/app/services/user.service';
import { DialogService } from 'src/app/services/dialog.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from './services/notification.service';

import { Fetch } from './services/fetch.service';
import { ToasterService } from './services/toaster.service';
import { DTORegistry } from 'src/dto/dto';
import { UpdateService } from 'src/app/services/update.service';
import { TenantService } from 'src/app/services/tenant.service';
import { ToastComponent } from './components/toast/toast.component';
import { NavmenuComponent } from './components/navmenu/menu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TenantSelectorComponent } from './components/tenantselector/tenantselector.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommandPaletteService } from '@dotglitch/ngx-common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { GlobalCommands } from 'src/app/global-commands';
import { openDataInMonacoPopup } from 'src/app/utils';
import { SiteTourOverlayComponent } from 'src/app/components/site-tour-overlay/site-tour-overlay.component';

import metadata from '../../package.json';
import { DtoService } from 'src/app/services/dto.service';
import { LazyLoaderComponent } from 'src/app/components/lazy-loader/lazy-loader.component';
import { LazyLoaderService } from 'src/app/components/lazy-loader/lazy-loader.service';
import { ComponentRegistration } from 'src/app/components/lazy-loader/types';
import { RoutePath, RouterService } from 'src/app/services/router.service';
import { NavMenuService } from 'src/app/services/nav-menu.service';
import { AsyncPipe } from '@angular/common';
import { AssetCacheService } from 'src/app/services/asset-cache.service';
import { PMORegistrations } from 'src/dto/pmo/dto';
import { EOBRegistrations } from 'src/dto/eob/dto';
import { EOMRegistrations } from 'src/dto/eom/dto';
import { AssetService } from 'src/app/services/asset.service';

declare let dT_;

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        MatToolbarModule,
        MatTooltipModule,
        MatIconModule,
        LazyLoaderComponent,
        TenantSelectorComponent,
        NavmenuComponent,
        ToastComponent,
        SiteTourOverlayComponent
    ]
})
export class RootComponent {

    version = metadata.version;

    isInitDone = false;
    showTour = false;
    isMobile = false;
    hideFrame = false;
    fatalError = false;
    headless = location.href.includes("headless=true");

    selectedPage: MenuItem;
    currentRootComponentRegistration: ComponentRegistration;
    currentRootComponent: unknown;

    // This ensures that the registrations are actually loaded.
    private dtoRegistrations = {
        pmo: PMORegistrations,
        eob: EOBRegistrations,
        eom: EOMRegistrations
    };

    private gc: GlobalCommands;

    rootNavigation: RoutePath;

    constructor(
        private readonly http: HttpClient,
        private readonly toaster: ToasterService,
        private readonly fetch: Fetch,
        public  readonly themeLoader: ThemeLoaderService,
        public  readonly user: UserService,
        public  readonly dto: DtoService,
        public  readonly dialog: DialogService,
        public  readonly matDialog: MatDialog,
        public  readonly tenant: TenantService,
        private readonly notification: NotificationService,
        private readonly updateService: UpdateService,
        private readonly lazyLoader: LazyLoaderService,
        private readonly commandPalette: CommandPaletteService,
        public readonly router: RouterService,
        private readonly navMenu: NavMenuService,
        private readonly cache: AssetCacheService,
        private readonly assetService: AssetService
    ) {
        // Expose an entrypoint for global access
        window['root'] = this;

        // Apply an error overlay if we somehow get embedded in an iframe.
        if (window != window.top) {
            document.body.classList.add("fatal-error");
            this.hideFrame = true;
            this.fatalError = true;
            return;
        }

        window.dtrum?.enableManualPageDetection();


        /**
         * Upon any change of the root route, we will load the corresponding top-level
         * component and trigger a change detection cycle on it and all of it's descendants.
         */
        this.router.rootPath$.subscribe(rootPath => this.rootNavigation = rootPath);

        // Is this necessary?
        this.onResize();
        setInterval(() => this.onResize(), 5000);
    }

    ngOnInit() {
        if (this.fatalError) return;

        DTORegistry.init(this.fetch, this.tenant);

        // Check if we are logged in.
        this.user.loadUserProfile()
            .then((result) => {
                this.initialize(result);
                this.isInitDone = true;
                // TODO enable tour based on user
            });
    }

    ngAfterViewInit(): void {
        document.body.classList.remove('loading');
    }

    initialize(data) {
        const user = data.user;

        // If we don't have a user, enable a panel under "F2"
        // that shows useful debug information without needing
        // to walk a user through devtools.
        if (!user) {
            this.commandPalette.initialize({
                keybind: "F2"
            });
            this.commandPalette.detachElementCommands();
            this.commandPalette.attachElementCommands([
                { label: "[debug]: show user status", action: () =>
                    openDataInMonacoPopup(this.matDialog, data)
                }
            ])
            return;
        };

        // Ensure the user has agreed to the terms of use.
        if (
                user.termAgreementDate == environment.termsOfUseVersion ||
                user.email.endsWith("@dynatrace.com")
            )
            this.user.termsAccepted = true;


        // Loads the syncfusion stylesheet
        this.themeLoader.loadSyncfusionTheme();

        this.user.preferences$.subscribe(p => {
            if (this.user.isPowerUser) {
                // Register commands
                this.commandPalette.detachElementCommands();
                const gc = new GlobalCommands(
                    this,
                    this.toaster,
                    this.fetch,
                    this.themeLoader,
                    this.user,
                    this.dialog,
                    this.matDialog,
                    this.tenant,
                    this.notification,
                    this.updateService,
                    this.lazyLoader,
                    this.commandPalette
                );
            }
            else {
                this.commandPalette.detachElementCommands()
            }
        })
    }

    onComponentLoaded(component) {
        this.currentRootComponent = component;
    }

    @HostListener('window:resize', ['$event'])
    private onResize(event?) {
        this.isMobile = (window.innerHeight / window.innerWidth > 1.5) || window.innerWidth < 900;
        document.body.classList.remove("mobile");
        document.body.classList.remove("desktop");

        this.isMobile  && document.body.classList.add("mobile");
        !this.isMobile && document.body.classList.add("desktop");
    }

    @HostListener('window:keydown.f1')
    startTour() {
        // Wait until the user is actually logged in -- do not show on login screen
        if (!this.user.value) {
            return;
        }

        // this.showTour = true;
    }
}
