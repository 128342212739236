import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { UserService } from 'src/app/services/user.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(
        private user: UserService
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Decorate all HTTP requests with our ESA-Client header.
        let tenant = window['ES-Tenant'];
        if (!tenant && this.user?.value?.tenants) {
            tenant = this.user.value.tenants[0].es_tenant_name;
        }
        else if (!tenant) {
            tenant = "unknown"
        }

        // If this is a user request, we need to omit the header
        if (/^\/api\/portal\/user($|[&?])/i.test(req.urlWithParams)) {
            // This simply won't get any additional headers.
        }
        else if (!req.headers.has("ES-Tenant")) {
            req = req.clone({
                headers: req.headers.set('ES-Tenant', tenant),
            });
        }

        return next.handle(req);
    }
}
