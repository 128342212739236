import { ActiveProjectService } from 'src/app/services/active-project.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { BaseMenuItem } from 'src/app/types/popups';

export const RootNavMenu: BaseMenuItem<{
    user: UserService,
    project: ActiveProjectService,
    tenant: TenantService
}>[] = [
    {
        icon: "query_stats",
        iconColor: "#6f2da8",
        label: "Observability Services",
        children: [
            {
                label: "Elevate Daily",
                link: "#/ElevateDaily"
            },
            {
                label: "Dynatrace Enterprise Coverage",
                children: [
                    // {
                    //     label: "Coverage Command Center",
                    //     link: "ccc"
                    // },
                    {
                        label: "Asset Import",
                        link: "#/asset-import",
                        isVisible: ctx => ctx.user.hasElevateAdminRole
                    },
                    {
                        label: "Management",
                        children: [
                            {
                                label: "Cost Centers",
                                link: "#/navigator?$scope=costcenter"
                            },
                            {
                                label: "Rate Groups",
                                link: "#/navigator?$scope=rategroup"
                            }
                        ]
                    }
                ],
                isVisible: ctx => ctx.user.groups.includes("ES-ROLE-ELEVATE_ADMIN")
            },
            {
                label: "Dynatrace Reporting",
                children: [
                    {
                        label: "License Consumption",
                        link: "#/licensereport"
                    },
                    {
                        label: "Problem Analysis",
                        link: "#/problem-analysis"
                    },
                    {
                        label: "Dynatrace UI Usage",
                        link: "#/dynatrace-ui-usage"
                    },
                    {
                        label: "DTU Usage",
                        link: "#/dtu-usage"
                    }
                ]
            },
            {
                label: "Dynatrace Engagement",
                children: [
                    {
                        label: "Service Report",
                        link: "#/ServiceReport"
                    },
                    {
                        label: "Support Ticket Activity",
                        link: "#/SupportReport"
                    },
                    {
                        label: "Services Marketplace",
                        children: [
                            {
                                label: "Flexpoint Management (Internal Preview)",
                                link: "#/flexpoint",
                                isVisible: ctx => ctx.user.groups.some(g => ["ES-ROLE-REGIONAL_MANAGER", "ES-ROLE-ELEVATE_ADMIN", "DYNATRACE_GRP_TENANT_OWNERS"].includes(g))
                            },
                            // TODO: This needs reviewed.
                            {
                                label: "Velocity Consulting",
                                link: "#/Frame?url=/wiki/ace-services-offerings.html"
                            },
                            {
                                label: "Enterprise Solutions",
                                link: "#/Frame?url=/wiki/esa-services-offerings.html"
                            },
                            {
                                label: "Enterprise Program Management",
                                link: "#/Frame?url=/wiki/epm-services-offerings.html"
                            },
                            {
                                label: "Extension Services",
                                link: "",
                                isVisible: () => false
                            },
                            {
                                label: "Business Insights",
                                link: "https://dev.elevate.dynatrace.com/#/Frame?url=/wiki/business-insights-offerings.html"
                            },
                            {
                                label: "Regional HOT Labs",
                                link: "https://www.dynatrace.com/services-support/ace-services/global-hot-labs/"
                            }
                        ]
                    },
                    {
                        label: "Tenant Settings",
                        link: "#/TenantPreferences",
                        isVisible: ctx => ctx.user.groups.some(g => ["DYNATRACE_GRP_TENANT_OWNERS","ES-ROLE-ELEVATE_ADMIN"].includes(g))
                    },
                    {
                        label: "Customer Project Library (CPL)",
                        linkTarget: "_blank",
                        linkTemplate: ctx => `https://dynatrace.sharepoint.com/sites/ACE_Services/CPL/Lists/Customers%20Projects%20List/All%20%20Geos.aspx?useFiltersInViewXml=1&FilterField1=LinkTitle&FilterValue1=${ctx.tenant.value['original_account_name'] || ctx.tenant.value.account_name}&FilterType1=Computed&FilterOp1=In`,
                        isVisible: ctx => ctx.user.groups.some(g => ["ES-ROLE-REGIONAL_MANAGER", "ES-ROLE-ELEVATE_ADMIN", "DYNATRACE_GRP_TENANT_OWNERS"].includes(g))
                    }
                ]
            }
        ]
    },
    {
        icon: "schema",
        iconColor: "rgb(74, 137, 210)",
        label: "Observability Blueprint",
        children: [
            {
                label: "Enterprise Infrastructure",
                link: "#/navigator?$scope=provider"
            },
            {
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            },
            {
                label: "Enterprise Platforms",
                link: "#/navigator?$scope=platform"
            },
            {
                label: "Dynatrace Infrastructure",
                children: [
                    {
                        label: "Clusters",
                        link: "#/navigator?$scope=dynatrace-cluster"
                    },
                    {
                        label: "Environments",
                        link: "#/navigator?$scope=dynatrace-environment"
                    }
                ]
            },
            {
                label: "Tools & Data",
                children: [
                    {
                        label: "Import",
                        link: "#/Power-Import-Wizard-eob?mode=eob"
                    },
                    {
                        label: "Export",
                        link: "#/Power-Export-Wizard-eob?mode=eob"
                    }
                ]
            }
        ]
    },
    {
        icon: "supervisor_account",
        iconColor: "rgb(194, 172, 25)",
        label: "Enterprise Organization",
        children: [
            {
                label: "Organizations",
                link: "#/navigator?$scope=organization"
            },
            {
                label: "Groups",
                link: "#/navigator?$scope=group"
            },
            {
                label: "Users",
                link: "#/navigator?$scope=user"
            },
            {
                label: "Tools & Data",
                children: [
                    {
                        label: "Import",
                        link: "#/Power-Import-Wizard-eom?mode=eom"
                    },
                    {
                        label: "Export",
                        link: "#/Power-Export-Wizard-eom?mode=eom"
                    }
                ]
            }
        ]
    },
    {
        icon: "view_kanban",
        iconColor: "rgb(109, 155, 68)",
        label: "Project Management",
        children: [
            {
                labelTemplate: ctx => "Home Project: " + (ctx.user.getFavoriteAsset("project")?.name || "None"),
                children: [
                    {
                        label: "Gantt",
                        linkTemplate: ctx => `#/navigator/Gantt?$scope=project.${ctx.user.getFavoriteAsset("project")?.id ?? -1}&maximized=true`
                    },
                    {
                        label: "Kanban",
                        linkTemplate: ctx => `#/navigator/Kanban?$scope=project.${ctx.user.getFavoriteAsset("project")?.id ?? -1}&maximized=true`
                    },
                    {
                        label: "Calendar",
                        linkTemplate: ctx => `#/navigator/Calendar?$scope=project.${ctx.user.getFavoriteAsset("project")?.id ?? -1}&maximized=true`
                    },
                    {
                        label: "Work List",
                        linkTemplate: ctx => `#/navigator/activetasks?$scope=project.${ctx.user.getFavoriteAsset("project")?.id ?? -1}&maximized=true`
                    },
                    {
                        label: "Issues",
                        linkTemplate: ctx => `#/navigator/issues?$scope=project.${ctx.user.getFavoriteAsset("project")?.id ?? -1}&maximized=true`
                    },
                    {
                        label: "Risks",
                        linkTemplate: ctx => `#/navigator/risks?$scope=project.${ctx.user.getFavoriteAsset("project")?.id ?? -1}&maximized=true`
                    },
                    {
                        label: "Files",
                        linkTemplate: ctx => `#/navigator/filestorage?$scope=project.${ctx.user.getFavoriteAsset("project")?.id ?? -1}&maximized=true`
                    }
                ]
            },
            {
                label: "Projects",
                link: "#/navigator?$scope=project",
            },
            {
                label: "Tools & Data",
                children: [
                    {
                        label: "Import",
                        link: "#/Power-Import-Wizard-pmo?mode=pmo"
                    },
                    {
                        label: "Export",
                        link: "#/Power-Export-Wizard-pmo?mode=pmo"
                    }
                ]
            },
            {
                label: "My Assigned Work",
                linkTemplate: ctx => `#/navigator?$scope=user.${ctx.user.id}&maximized=true`
            }
        ]
    }//,
    // {
    //     icon: "business_center",
    //     iconColor: "rgb(126, 111, 92)",
    //     label: "Dynatrace Managers Office",
    //     isVisible: ctx => ["ES-ROLE-REGIONAL_MANAGER","ES-ROLE-ELEVATE_ADMIN"].some(g => ctx.user.groups.includes(g)),
    //     children: [
    //         {
    //             label: "Elevate Usage",
    //             isVisible: ctx => ["ES-ROLE-ELEVATE_ADMIN"].some(g => ctx.user.groups.includes(g)),
    //             children: [
    //                 {
    //                     label: "Activity Overview",
    //                     link: "#/PowerBi?reportId=Hidden-esa_builtin_portal_activity&workspace=General%20Portal"
    //                 },
    //                 {
    //                     label: "Video Analysis",
    //                     link: "#/PowerBI?reportId=Hidden-DTUTrainingVideoStats&workspace=General%20Portal"
    //                 },
    //                 {
    //                     label: "Global Feedback",
    //                     link: "#/elevate-feedback"
    //                 }
    //             ]
    //         },
    //         {
    //             label: "Employee Assessments",
    //             children: [
    //                 {
    //                     label: "Services Feedback Report",
    //                     link: "#/PowerBI?reportId=Hidden-ElevateFeedbackReport&workspace=General+Portal",
    //                     isVisible: ctx => ["ES-ROLE-ELEVATE_ADMIN"].some(g => ctx.user.groups.includes(g))
    //                 },
    //                 {
    //                     label: "Employee Milestones",
    //                     link: "#/PowerBI?reportId=Hidden-esa_builtin_Milestones_Dashboard&workspace=General%20Portal"
    //                 },
    //                 {
    //                     label: "Skills & Certifications",
    //                     link: "#/PowerBI?reportId=Hidden-esa_builtin_Skills_Certs_Dashboard&workspace=Employee%20Portal"
    //                 }
    //             ]
    //         },
    //         {
    //             label: "Performance Reviews",
    //             children: [
    //                 {
    //                     label: "NORAM",
    //                     link: "#/PowerBI?reportId=Hidden-esa_builtin_Performance_Review_Dashboard_NORAM&workspace=General%20Portal"
    //                 },
    //                 {
    //                     label: "LATAM",
    //                     link: "#/PowerBI?reportId=Hidden-esa_builtin_Performance_Review_Dashboard_LATAM&workspace=General%20Portal"
    //                 },
    //                 {
    //                     label: "EMEA",
    //                     link: "#/PowerBI?reportId=Hidden-esa_builtin_Performance_Review_Dashboard_EMEA&workspace=General%20Portal"
    //                 },
    //                 {
    //                     label: "APAC",
    //                     link: "#/PowerBI?reportId=Hidden-esa_builtin_Performance_Review_Dashboard_APAC&workspace=General%20Portal"
    //                 }
    //             ]
    //         }
    //     ]
    // }
]
