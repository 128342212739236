<!-- 200px expanded; 56px collapsed. 300px when expanded on mobile -->
<div class="menu" [class.collapsed]="collapsed">
    <a href="#/ElevateDaily">
        @if (!collapsed) {
            <svg
                id="logo"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 759 130.1"
                style="enable-background:new 0 0 759 130.1; margin: 6px 6px 12px 6px"
                xml:space="preserve"
            >
                <style type="text/css">
                    .elvst00{fill:url(#ELV_SVG_0_);stroke:var(--text-bright-color);stroke-miterlimit:10;}
                    .elvst01{fill:var(--text-bright-color)}
                </style>
                <linearGradient id="ELV_SVG_0_" gradientUnits="userSpaceOnUse" x1="500.55" y1="1059.2" x2="500.55" y2="974.1288" gradientTransform="matrix(1 0 0 1 0 -950)">
                    <stop offset="0" style="stop-color:#6F2DA8"/>
                    <stop offset="1" style="stop-color:#1496FF"/>
                </linearGradient>
                <path class="elvst00" d="M525.2,109.1c-8-24.4-16-48.9-24.2-74.1c-0.2-0.5,0.7-0.7,0.4-0.3c-0.7,1.1-1,1.9-1.2,2.6
                    c-7.7,23.5-16,46.8-23.6,70.3c-0.1,0.4-1,1.1-1,1.6c-2.4,0-7.4,0-9.7,0c9-26.4,19.5-52.9,28.6-79.2c0.9-2.5,3.9-5.8,5.4-5.8
                    c1.5-0.1,7.4-0.8,9.2,5.6c9,26.4,17.2,52.9,26.1,79.4L525.2,109.1L525.2,109.1z"/>
                <path class="elvst01" d="M383.9,109.1c-1.6,0-9.1,0-10.7,0c-9-27.9-18-55.8-27-83.9c5.1-1.7,7.5-1,10.3,5.3c6.2,19.7,12.3,39.5,18.5,59.2
                    c0.8,2.4,1.7,4.8,3,8.6c4.8-14.8,9.3-28.5,13.7-42.2c2.9-9.1,5.6-18.3,8.8-27.2c0.9-2.5,0.5-3.7,2-5.7c0.6,0.6,7-0.3,7.6,0.4"/>
                <path class="elvst01" d="M123,109.1V24h9.9v75.1h32.4c0.1,3.2,0.2,7.6,0.3,10C165.6,109.1,123,109.1,123,109.1z"/>
                <path class="elvst01" d="M609.5,109.1V32.7h-18.8v-8.8h47.7c0.1,2.3,0.2,6.4,0.3,9.3h-19.2v75.9L609.5,109.1L609.5,109.1z"/>
                <path class="elvst01" d="M0,60.3h44.1c1.8,6-0.7,8.5-3.4,8.4c-9.5-0.2-18.9,0-28.4,0c-1.9,0-0.9,0-3.1,0v31.5h35.4c0.1,3.2,0.2,6.6,0.3,9H0
                    C0,93.5,0,75.9,0,60.3L0,60.3z"/>
                <path class="elvst01" d="M0,23.5h44.5v9.1H0V23.5z"/>
                <path class="elvst01" d="M240.7,60.7h44.1c1.8,6-0.7,8.5-3.4,8.4c-9.5-0.2-18.9,0-28.4,0c-1.9,0-0.9,0-3.1,0v31.5h35.4c0.1,3.2,0.2,6.6,0.3,9h-44.9
                    C240.7,93.9,240.7,76.3,240.7,60.7L240.7,60.7z"/>
                <path class="elvst01" d="M240.7,23.9h44.5V33h-44.5C240.7,33,240.7,23.9,240.7,23.9z"/>
                <path class="elvst01" d="M702,61.3h44c1.8,6-0.7,8.5-3.4,8.4c-9.5-0.2-18.9,0-28.4,0c-1.9,0-0.9,0-3.2,0v31.5h35.4c0.1,3.2,0.2,6.6,0.3,9h-45
                    C702,94.6,702,76.9,702,61.3L702,61.3z"/>
                <path class="elvst01" d="M701.5,26.1H746v9.1h-44.5V26.1z"/>
            </svg>
        }
        @else {
            <svg
                [matTooltip]="collapsed ? 'Elevate' : null"
                version="1.1"
                viewBox="0 0 138.41756 140.0551"
                enable-background="new 0 0 800 142"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:svg="http://www.w3.org/2000/svg"
                style="width: 100%; padding: 4px; margin-bottom: 4px"
            >
                <g id="Background" transform="translate(-0.99122085,-0.97244898)">
                </g>
                <g id="Vertical" transform="translate(-0.99122085,-0.97244898)">
                </g>
                <g id="Horizontal" transform="translate(-0.99122085,-0.97244898)">
                </g>
                <g id="Dynatrace_Color" transform="translate(-0.99122085,-0.97244898)">
                </g>
                <g id="g42" transform="translate(-0.99122085,-0.97244898)">
                    <path fill="#1496ff"
                        d="m 47.7,12.7 c -1.8,9.5 -4,23.6 -5.2,37.9 -2.1,25.2 -0.8,42.1 -0.8,42.1 L 6.2,126.4 c 0,0 -2.7,-18.9 -4.1,-40.2 C 1.3,73 1,61.4 1,54.4 1,54 1.2,53.6 1.2,53.2 1.2,52.7 1.8,48 6.4,43.6 11.4,38.8 48.3,9.9 47.7,12.7 Z"
                        id="path30" />
                    <path fill="#1284ea"
                        d="m 47.7,12.7 c -1.8,9.5 -4,23.6 -5.2,37.9 0,0 -39.3,-4.7 -41.5,4.8 0,-0.5 0.7,-6.3 5.3,-10.7 5,-4.8 42,-34.8 41.4,-32 z"
                        id="path32" />
                    <path fill="#b4dc00"
                        d="m 1,53.1 c 0,0.7 0,1.4 0,2.2 0.4,-1.7 1.1,-2.9 2.5,-4.8 2.9,-3.7 7.6,-4.7 9.5,-4.9 9.6,-1.3 23.8,-2.8 38.1,-3.2 25.3,-0.8 42,1.3 42,1.3 L 128.6,10 C 128.6,10 110,6.5 88.8,4 74.9,2.3 62.7,1.4 55.8,1 55.3,1 50.4,0.4 45.8,4.8 40.8,9.6 15.4,33.7 5.2,43.4 0.6,47.8 1,52.7 1,53.1 Z"
                        id="path34" />
                    <path fill="#6f2da8"
                        d="m 127.3,96.2 c -9.6,1.3 -23.8,2.9 -38.1,3.4 -25.3,0.8 -42.1,-1.3 -42.1,-1.3 l -35.5,33.8 c 0,0 18.8,3.7 40,6.1 13,1.5 24.5,2.3 31.5,2.7 0.5,0 1.3,-0.4 1.8,-0.4 0.5,0 5.4,-0.9 10,-5.3 5,-4.8 35.2,-39.3 32.4,-39 z"
                        id="path36" />
                    <path fill="#591f91"
                        d="m 127.3,96.2 c -9.6,1.3 -23.8,2.9 -38.1,3.4 0,0 2.7,39.5 -6.8,41.2 0.5,0 7,-0.3 11.6,-4.7 5,-4.8 36.1,-40.2 33.3,-39.9 z"
                        id="path38" />
                    <path fill="#73be28"
                        d="m 84.5,141 c -0.7,0 -1.4,-0.1 -2.2,-0.1 1.8,-0.3 3,-0.9 4.9,-2.3 3.8,-2.7 5,-7.4 5.4,-9.3 1.7,-9.5 4,-23.6 5.1,-37.9 2,-25.2 0.8,-42 0.8,-42 L 134,15.6 c 0,0 2.6,18.8 4.1,40.1 0.9,13.9 1.2,26.2 1.3,33 0,0.5 0.4,5.4 -4.2,9.8 -5,4.8 -30.4,29 -40.5,38.7 -4.8,4.4 -9.7,3.8 -10.2,3.8 z"
                        id="path40" />
                </g>
            </svg>

        }
    </a>

    <div style="margin: 10px 0 4px 0">
        @if (collapsed) {
            <a class="item"
                matTooltip="Account: {{tenant.value.account_name}}"
                matTooltipPosition="right"
                (click)="
                    showAccountDropdown = dropdownHiddenTime > (Date.now() - 500) ? false : true;
                    showAccountDropdown ? _.focus() : null
                "
            >
                @if (loadingAccount) {
                    <mat-progress-spinner mode="indeterminate" style="margin: 8px"/>
                }
                @else {
                    <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="var(--text-color)">
                            <path
                                d="M320-80q-33 0-56.5-23.5T240-160v-80h-80q-33 0-56.5-23.5T80-320v-80h80v80h80v-320q0-33 23.5-56.5T320-720h320v-80h-80v-80h80q33 0 56.5 23.5T720-800v80h80q33 0 56.5 23.5T880-640v480q0 33-23.5 56.5T800-80H320Zm0-80h480v-480H320v480ZM80-480v-160h80v160H80Zm0-240v-80q0-33 23.5-56.5T160-880h80v80h-80v80H80Zm240-80v-80h160v80H320Zm0 640v-480 480Z" />
                        </svg>
                    </span>
                    <span class="label"></span>
                }
            </a>
        }
        @else {
            <div style="margin-bottom: 6px; margin-left: 12px">Account:</div>
            @if (loadingAccount) {
                <mat-progress-bar mode="indeterminate"/>
            }
            @else {
                <div
                    class="account-status"
                    (click)="
                        showAccountDropdown = dropdownHiddenTime > (Date.now() - 500) ? false : true;
                        showAccountDropdown ? _.focus() : null
                    "
                >
                    {{tenant.value.account_name}}
                </div>
            }
        }

        <div class="account-popup" [class.visible]="showAccountDropdown">
            <input
                #_
                class="search"
                type="text"
                (keyup)="filterAccounts(_.value)"
                (cut)="filterAccounts(_.value)"
                (paste)="filterAccounts(_.value)"
                (keydown)="$event.key == 'Enter' ? (onAccountChange(filteredAccounts?.[0]?.es_tenant_name) && hideDropdown(_)) : 0"
                (blur)="hideDropdown(_, 'blur')"
            >
            <div class="account-list">
                @for (account of filteredAccounts; track account) {
                    <a
                        class="account"
                        [href]="'#/?es-tenant=' + account.es_tenant_name"
                        (pointerdown)="onAccountChange(account.es_tenant_name); _.value = ''"
                    >
                        <div class="ac_line">
                            <span class="label" [innerHTML]="account['_renderedLabel'] || account.account_name"></span>
                            <span class="hint" [innerHTML]="account['_renderedHint'] || account.es_tenant_name"></span>
                        </div>
                    </a>
                }
            </div>
        </div>
    </div>

    <hr/>

    <div>
        @for (rootitem of rootMenu; track rootitem) {
            @if (rootitem.isVisible?.($any({ user, tenant }), null)) {
                <!-- NOP -->
            }
            @if (rootitem.link) {
                <a class="item"
                    [matTooltip]="collapsed ? rootitem.label : null"
                    matTooltipPosition="right"
                    [href]="rootitem.link ? rootitem.link : null"
                >
                    <span class="icon">
                        <mat-icon
                            [fontIcon]="rootitem.icon"
                            [style.color]="rootitem.iconColor"
                        />
                    </span>
                    <span class="label">{{rootitem.label}}</span>
                </a>
            }
            @else {
                <div class="item"
                    [matTooltip]="collapsed ? rootitem.label : null"
                    matTooltipPosition="right"
                    [ngx-menu]="$any(rootitem.children)"
                    [ngx-menu-config]="{ position: 'right', alignment: 'start', customClass: ['dt-menu'] }"
                    [ngx-menu-context]="{ user, tenant }"
                >
                    <span class="icon">
                        <mat-icon
                            [fontIcon]="rootitem.icon"
                            [style.color]="rootitem.iconColor"
                        />
                    </span>
                    <span class="label">{{rootitem.label}}</span>
                </div>
            }
        }
    </div>

    <hr/>

    <div style="overflow-y: auto; flex: 1">
        @if (!collapsed) {
            <div style="margin: 8px">
                {{personaLabel || "No Persona Selected"}}
            </div>
        }

        @if (!personaLabel && !collapsed) {
            <button
                mat-flat-button
                (click)="openPersonaModal()"
            >
                Choose Persona
            </button>
        }

        @for (rootitem of personaMenu; track rootitem) {
            @if (rootitem.link || rootitem["_link"]) {
                <a class="item"
                    [matTooltip]="collapsed ? rootitem.label : null"
                    matTooltipPosition="right"
                    [attr.href]="rootitem['_link'] || rootitem.link"
                    [attr.target]="rootitem.linkTarget"
                >
                    <span class="icon">
                        <mat-icon
                            [fontIcon]="rootitem.icon"
                            [style.color]="rootitem.iconColor"
                        />
                    </span>
                    <span class="label">{{rootitem.label}}</span>
                </a>
            }
            @else {
                <div class="item"
                    [matTooltip]="collapsed ? rootitem.label : null"
                    matTooltipPosition="right"
                    [ngx-menu]="$any(rootitem['children'])"
                    [ngx-menu-config]="{ position: 'right', alignment: 'start', customClass: ['dt-menu'] }"
                    [ngx-menu-context]="{ user, tenant }"
                >
                    <span class="icon">
                        <mat-icon
                            [fontIcon]="rootitem.icon"
                            [style.color]="rootitem.iconColor"
                        />
                    </span>
                    <span class="label">{{rootitem.label}}</span>
                </div>
            }
        }
    </div>

    <hr/>

    <div class="menu-boltons">
        <div class="item collapse-button"
            [matTooltip]="collapsed ? 'Expand Dock' : null"
            matTooltipPosition="right"
            (click)="collapsed = !collapsed"
        >
            <!-- Overlay search functionality -->

            <span class="icon">
                <mat-icon [fontIcon]="collapsed ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left'"></mat-icon>
            </span>
            <span class="label">Collapse</span>
        </div>

        <div
            #_
            class="item"
            (click)="openNotifications(_)"
            [matTooltip]="collapsed ? 'Notifications' : null"
            matTooltipPosition="right"
        >
            <span class="icon" [matBadge]="notificationCount" [matBadgeHidden]="!collapsed || notificationCount == 0"><mat-icon>notifications</mat-icon></span>
            <span class="label" [matBadge]="notificationCount" [matBadgeHidden]="collapsed || notificationCount == 0" matBadgeOverlap="false">Notifications</span>
        </div>


        <div class="item support-button"
            [matTooltip]="collapsed ? 'Support' : null"
            matTooltipPosition="right"
            [ngx-menu]="supportLinks"
            [ngx-menu-config]="{ position: 'right', alignment: 'end', customClass: ['dt-menu']}"
            >
            <!-- Overlay search functionality -->
            <span class="icon">
                <mat-icon>support</mat-icon>
            </span>
            <span class="label">Support</span>
        </div>

        <div class="item user-profile"
            [matTooltip]="collapsed ? user.name : null"
            matTooltipPosition="right"
            [ngx-menu]="profileLinks"
            [ngx-menu-config]="{ position: 'right', alignment: 'end', customClass: ['dt-menu']}"
        >
            <!-- Overlay search functionality -->
            <span class="icon">
                <mat-icon>account_circle</mat-icon>
            </span>
            <span class="label">{{user.name}}</span>
        </div>
    </div>

    <hr/>
    @if (!collapsed) {
        <svg
            id="team"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 637 259"
            style="enable-background:new 0 0 637 259; margin: 6px"
            xml:space="preserve"
        >
            <style type="text/css">
                .elvst0{fill:var(--text-bright-color);}
                .elvst1{fill:#1496FF;}
                .elvst2{fill:#1284EA;}
                .elvst3{fill:#BEDF2A;}
                .elvst4{fill:#6F2DA8;}
                .elvst5{fill:#591F91;}
                .elvst6{fill:#73BE28;}
                .elvst7{fill:#1A1A1A;}
                .elvst8{fill:url(#ELV_SVG_1_);}
                .elvst9{fill:url(#ELV_SVG_2_);}
                .elvst10{fill:url(#ELV_SVG_3_);}
                .elvst11{fill:url(#ELV_SVG_4_);}
                .elvst12{fill:url(#ELV_SVG_5_);}
                .elvst13{fill:url(#ELV_SVG_6_);}
                .elvst14{fill:url(#ELV_SVG_7_);}
                .elvst15{fill:url(#ELV_SVG_8_);}
                .elvst16{fill:url(#ELV_SVG_9_);}
                .elvst17{fill:url(#ELV_SVG_10_);}
                .elvst18{fill:url(#ELV_SVG_11_);}
                .elvst19{fill:url(#ELV_SVG_12_);}
                .elvst20{fill:url(#ELV_SVG_13_);}
                .elvst21{fill:url(#ELV_SVG_14_);}
                .elvst22{fill:url(#ELV_SVG_15_);}
                .elvst23{fill:url(#ELV_SVG_16_);}
                .elvst24{fill:url(#ELV_SVG_17_);}
                .elvst25{fill:url(#ELV_SVG_18_);}
                .elvst26{fill:url(#ELV_SVG_19_);}
                .elvst27{fill:url(#ELV_SVG_20_);}
                .elvst28{fill:url(#ELV_SVG_21_);}
                .elvst29{fill:url(#ELV_SVG_22_);}
                .elvst30{fill:url(#ELV_SVG_23_);}
                .elvst31{fill:url(#ELV_SVG_24_);}
                .elvst32{fill:url(#ELV_SVG_25_);}
                .elvst33{fill:url(#ELV_SVG_26_);}
                .elvst34{fill:url(#ELV_SVG_27_);}
                .elvst35{fill:url(#ELV_SVG_28_);}
                .elvst36{fill:url(#ELV_SVG_29_);}
                .elvst37{fill:url(#ELV_SVG_30_);}
                .elvst38{fill:url(#ELV_SVG_31_);}
                .elvst39{fill:url(#ELV_SVG_32_);}
            </style>
            <g id="Logo">
                <g>
                    <linearGradient id="ELV_SVG_1_" gradientUnits="userSpaceOnUse" x1="0.5471" y1="129.9933" x2="298.6438" y2="129.9933">
                        <stop offset="0" style="stop-color:#6C31AB"/>
                        <stop offset="0.3485" style="stop-color:#6F2DA8"/>
                        <stop offset="1" style="stop-color:#1496FF"/>
                    </linearGradient>
                    <path class="elvst8" d="M295.4,224.8L174,15c-5.4-9.4-14.9-14.1-24.4-14.1c-9.5,0-19,4.7-24.4,14.1L3.7,224.8
                        C-8,245,14.8,267.7,35,255.8l114.6-67.6l114.6,67.6C284.4,267.7,307.2,245,295.4,224.8z"/>
                    <linearGradient id="ELV_SVG_2_" gradientUnits="userSpaceOnUse" x1="97.3411" y1="62.8832" x2="325.9297" y2="194.8589">
                        <stop offset="0" style="stop-color:#1496FF;stop-opacity:0"/>
                        <stop offset="1" style="stop-color:#1496FF"/>
                    </linearGradient>
                    <path class="elvst9" d="M295.4,224.8L174,15c-5.4-9.4-14.9-14.1-24.4-14.1c-9.5,0-19,4.7-24.4,14.1L56.7,133.4l207.5,122.4
                        C284.4,267.7,307.2,245,295.4,224.8z"/>

                        <linearGradient id="ELV_SVG_3_" gradientUnits="userSpaceOnUse" x1="-12662.7441" y1="62.8832" x2="-12434.1553" y2="194.8589" gradientTransform="matrix(-1 0 0 1 -12460.8916 0)">
                        <stop offset="0" style="stop-color:#6F2DA8;stop-opacity:0"/>
                        <stop offset="1" style="stop-color:#6F2DA8"/>
                    </linearGradient>
                    <path class="elvst10" d="M3.7,224.8L125.2,15c5.4-9.4,14.9-14.1,24.4-14.1c9.5,0,19,4.7,24.4,14.1l68.5,118.4L35,255.8
                        C14.8,267.7-8,245,3.7,224.8z"/>
                    <linearGradient id="ELV_SVG_4_" gradientUnits="userSpaceOnUse" x1="149.5958" y1="196.2121" x2="0.362" y2="196.2121">
                        <stop offset="0" style="stop-color:#000000;stop-opacity:0.6"/>
                        <stop offset="4.552233e-02" style="stop-color:#09040E;stop-opacity:0.5727"/>
                        <stop offset="0.2112" style="stop-color:#28103D;stop-opacity:0.4733"/>
                        <stop offset="0.3771" style="stop-color:#421B63;stop-opacity:0.3738"/>
                        <stop offset="0.5403" style="stop-color:#552381;stop-opacity:0.2758"/>
                        <stop offset="0.7002" style="stop-color:#642897;stop-opacity:0.1799"/>
                        <stop offset="0.8553" style="stop-color:#6C2CA4;stop-opacity:8.684903e-02"/>
                        <stop offset="1" style="stop-color:#6F2DA8;stop-opacity:0"/>
                    </linearGradient>
                    <path class="elvst11" d="M149.6,188.2L34.8,255.8c-20.2,11.9-43-10.8-31.3-31l53.1-91.4L149.6,188.2z"/>
                </g>
                <g>
                    <path class="elvst0" d="M416.8,188l-6.7-20.9h-37.4l-6.8,21.1c-0.5,1.6-1.9,2.6-3.6,2.6h-11.8c-3.3,0-5.7-3.3-4.6-6.5l32.6-93.1
                        c1.2-3.3,4.3-5.5,7.8-5.5h11c3.5,0,6.6,2.2,7.8,5.5l32.1,93.1c1.1,3.2-1.3,6.4-4.6,6.4h-11.8C418.9,190.8,417.3,189.7,416.8,188z
                        M378.3,150h26.5l-13-41.2L378.3,150z"/>
                    <path class="elvst0" d="M534,184.2c0,2.2-1.5,4.1-3.6,4.6c-3.9,0.9-10.7,2.1-18.8,2.1c-36.2,0-52.3-23.6-52.3-53.1
                        c0-29.4,16-53.1,52.3-53.1c8.1,0,14.8,1.2,18.8,2.1c2.1,0.5,3.6,2.4,3.6,4.6v9.9c0,3-2.8,5.3-5.8,4.6c-3.7-0.9-8.8-1.8-13.9-1.8
                        c-21.3,0-30,14.1-30,33.6c0,19.5,8.7,33.9,30,33.9c5.1,0,10.1-0.9,13.9-1.8c2.9-0.7,5.8,1.5,5.8,4.6V184.2z"/>
                    <path class="elvst0" d="M634.4,90.6v10c0,2.7-2.2,4.8-4.8,4.8h-38.2v21.9H623c2.7,0,4.8,2.2,4.8,4.8v8c0,2.7-2.2,4.8-4.8,4.8h-31.5
                        v25.2h40.4c2.7,0,4.8,2.2,4.8,4.8v10c0,2.7-2.2,4.8-4.8,4.8h-57.6c-3.3,0-6-2.7-6-6V91.8c0-3.3,2.7-6,6-6h55.3
                        C632.3,85.8,634.4,87.9,634.4,90.6z"/>
                </g>
                <g>
                    <g>
                        <g>
                            <path class="elvst0" d="M554.9,14h-6.7c-1.9,0-3.2,0.4-4,1.2c-0.8,0.8-1.2,2.1-1.2,3.9v23h-4V18.8c0-1.8,0.3-3.7,1.3-5.1
                                c1.7-2.7,5-3.2,7.4-3.2h7.2L554.9,14L554.9,14z"/>
                        </g>
                        <g>
                            <path class="elvst0" d="M529.1,38.5c-1.9,0-3.2-0.4-4-1.2c-0.8-0.8-1.2-2-1.2-3.8V14h8.4v-3.6H524V0.9h-4v32.8
                                c0,1.8,0.3,3.7,1.3,5.1c1.7,2.7,5,3.2,7.4,3.2h7.2v-3.6L529.1,38.5z"/>
                        </g>
                        <path class="elvst0" d="M424.7,0.9v9.5h-8.4c-5.1,0-8.2,1.5-10.1,3.4c-2.9,2.9-2.9,7-2.9,7.4c0,0.5,0,9.7,0,10.2
                            c0,0.4,0,4.5,2.9,7.4c1.9,1.8,5,3.4,10.1,3.4h3.7c2.4,0,5.7-0.5,7.4-3.2c0.9-1.4,1.2-3.4,1.3-5.1V0.9H424.7z M423.5,37.4
                            c-0.8,0.8-2.1,1.2-4,1.2h-3.6c-3.3,0-5.4-0.9-6.6-2.2c-1.5-1.4-2-3.4-2-4.9V21.1c0-1.5,0.5-3.4,2-4.9c1.3-1.2,3.3-2.2,6.6-2.2
                            h8.8v19.5C424.7,35.3,424.3,36.6,423.5,37.4z"/>
                        <path class="elvst0" d="M590.9,16.2c1.3-1.2,3.3-2.2,6.6-2.2h9.6v-3.6h-9.2c-5.1,0-8.2,1.5-10.1,3.4c-2.9,2.9-2.9,7-2.9,7.4
                            c0,0.3,0,9.9,0,10.2c0,0.4,0,4.5,2.9,7.4c1.9,1.8,5,3.4,10.1,3.4h9.2v-3.6h-9.6c-3.3,0-5.4-0.9-6.6-2.2c-1.5-1.4-2-3.4-2-4.9
                            V21.1C589,19.6,589.5,17.6,590.9,16.2z"/>
                        <path class="elvst0" d="M515.9,21.2c0-0.4,0-4.5-2.9-7.4c-1.9-1.8-5-3.4-10.1-3.4H494V14h9.3c3.3,0,5.4,0.9,6.6,2.2
                            c1.5,1.4,2,3.4,2,4.9v3.1h-12.7c-2.4,0-5.7,0.5-7.4,3.2c-0.9,1.4-1.2,3.4-1.3,5.1v1.3c0,1.8,0.3,3.7,1.3,5.1
                            c1.7,2.7,5,3.2,7.4,3.2h8.1c2.4,0,5.7-0.5,7.4-3.2c0.9-1.4,1.2-3.4,1.3-5.1C515.9,33.7,515.9,23.2,515.9,21.2z M510.7,37.4
                            c-0.8,0.8-2.1,1.2-4,1.2h-7c-1.9,0-3.2-0.4-4-1.2c-0.8-0.8-1.2-2.1-1.2-3.8v-0.9c0-1.8,0.4-3.1,1.2-3.8c0.8-0.8,2.1-1.2,4-1.2
                            h12.2v5.9C511.9,35.3,511.5,36.6,510.7,37.4z"/>
                        <path class="elvst0" d="M581.8,21.2c0-0.4,0-4.5-2.9-7.4c-1.9-1.8-5-3.4-10.1-3.4h-8.9V14h9.3c3.3,0,5.4,0.9,6.6,2.2
                            c1.5,1.4,2,3.4,2,4.9v3.1H565c-2.4,0-5.7,0.5-7.4,3.2c-0.9,1.4-1.2,3.4-1.3,5.1v1.3c0,1.8,0.3,3.7,1.3,5.1c1.7,2.7,5,3.2,7.4,3.2
                            h8.1c2.4,0,5.7-0.5,7.4-3.2c0.9-1.4,1.2-3.4,1.3-5.1C581.8,33.7,581.8,23.2,581.8,21.2z M576.6,37.4c-0.8,0.8-2.1,1.2-4,1.2h-7
                            c-1.9,0-3.2-0.4-4-1.2c-0.8-0.8-1.2-2.1-1.2-3.8v-0.9c0-1.8,0.4-3.1,1.2-3.8c0.8-0.8,2.1-1.2,4-1.2h12.2v5.9
                            C577.8,35.3,577.4,36.6,576.6,37.4z"/>
                        <polygon class="elvst0" points="460.4,10.4 456.2,10.4 446.1,36.7 436,10.4 431.9,10.4 444.1,42.1 440.4,51.6 444.6,51.6 			"/>
                        <path class="elvst0" d="M487.3,21.2c0-0.4,0-4.5-2.9-7.4c-1.8-1.8-4.8-3.3-9.6-3.4h-0.4c-4.8,0.1-7.7,1.6-9.6,3.4
                            c-2.9,2.9-2.9,7-2.9,7.4c0,0.5,0,19.3,0,21h4V21.1c0-1.5,0.5-3.4,2-4.9c1.2-1.2,3.6-2.1,6.7-2.2c3.2,0,5.5,1,6.7,2.2
                            c1.4,1.4,2,3.4,2,4.9v21.1h4C487.3,40.5,487.3,21.7,487.3,21.2z"/>
                        <path class="elvst0" d="M631.8,13.8c-1.8-1.8-4.8-3.3-9.6-3.4h-0.4c-4.8,0.1-7.7,1.6-9.6,3.4c-2.9,2.9-2.9,7-2.9,7.4v10.2
                            c0,0.4,0,4.5,2.9,7.4c1.8,1.8,4.8,3.3,9.6,3.4h9.4v-3.6H622c-3.2,0-5.5-1-6.7-2.2c-1.4-1.4-2-3.4-2-4.9v-3.7h21.4v-6.6
                            C634.7,20.7,634.7,16.6,631.8,13.8z M613.4,24.2v-3.1c0-1.5,0.5-3.4,2-4.9c1.2-1.2,3.6-2.1,6.7-2.2c3.2,0,5.5,1,6.7,2.2
                            c1.4,1.4,2,3.4,2,4.9v3.1H613.4z"/>
                    </g>
                    <path class="elvst1" d="M362.6,5.2c-0.7,3.4-1.4,8.5-1.9,13.7c-0.8,9.1-0.3,15.3-0.3,15.3l-12.9,12.2c0,0-1-6.8-1.5-14.6
                        c-0.3-4.8-0.4-9-0.4-11.5c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.2-1.9,1.9-3.5C349.4,14.6,362.8,4.1,362.6,5.2z"/>
                    <path class="elvst2" d="M362.6,5.2c-0.7,3.4-1.4,8.5-1.9,13.7c0,0-14.2-1.7-15,1.7c0-0.2,0.3-2.3,1.9-3.9
                        C349.4,15,362.8,4.1,362.6,5.2z"/>
                    <path class="elvst3" d="M345.6,19.8c0,0.3,0,0.5,0,0.8c0.1-0.6,0.4-1.1,0.9-1.7c1.1-1.3,2.8-1.7,3.4-1.8c3.5-0.5,8.6-1,13.8-1.2
                        c9.2-0.3,15.2,0.5,15.2,0.5l12.9-12.2c0,0-6.7-1.3-14.4-2.2c-5-0.6-9.5-0.9-12-1.1c-0.2,0-2-0.2-3.6,1.4c-1.8,1.7-11,10.5-14.7,14
                        C345.5,17.9,345.6,19.6,345.6,19.8z"/>
                    <path class="elvst4" d="M391.4,35.4c-3.5,0.5-8.6,1.1-13.8,1.2c-9.2,0.3-15.3-0.5-15.3-0.5l-12.9,12.2c0,0,6.8,1.3,14.5,2.2
                        c4.7,0.5,8.9,0.8,11.4,1c0.2,0,0.5-0.1,0.7-0.1c0.2,0,2-0.3,3.6-1.9C381.5,47.8,392.4,35.3,391.4,35.4z"/>
                    <path class="elvst5" d="M391.4,35.4c-3.5,0.5-8.6,1.1-13.8,1.2c0,0,1,14.3-2.5,14.9c0.2,0,2.5-0.1,4.2-1.7
                        C381.1,48.1,392.4,35.3,391.4,35.4z"/>
                    <path class="elvst6" d="M375.9,51.6c-0.3,0-0.5,0-0.8,0c0.7-0.1,1.1-0.3,1.8-0.8c1.4-1,1.8-2.7,2-3.4c0.6-3.4,1.4-8.5,1.8-13.7
                        c0.7-9.1,0.3-15.2,0.3-15.2l12.9-12.2c0,0,0.9,6.8,1.5,14.5c0.3,5,0.4,9.5,0.5,12c0,0.2,0.1,2-1.5,3.5c-1.8,1.7-11,10.5-14.7,14
                        C377.8,51.8,376.1,51.6,375.9,51.6z"/>
                </g>
                <g>
                    <path class="elvst0" d="M366.4,231.2c-2.4-0.8-4.5-1.2-6.4-1.2c-1.5,0-2.7,0.3-3.4,0.9c-0.8,0.6-1.1,1.4-1.1,2.4c0,1,0.4,1.8,1.2,2.6
                        c0.8,0.7,2.1,1.5,3.8,2.5c1.1,0.6,2.2,1.3,3.2,2c1.1,0.7,2.1,1.8,3.1,3.2c1,1.4,1.5,3.2,1.5,5.4c0,3.3-1.1,5.7-3.3,7.5
                        c-2.2,1.7-5.2,2.6-8.9,2.6c-2.8,0-5.4-0.4-7.6-1.3v-5.8h0.3c2.7,1,5,1.6,7,1.6c1.6,0,2.9-0.4,3.8-1.1c0.9-0.7,1.4-1.6,1.4-2.8
                        c0-0.8-0.2-1.5-0.7-2.1c-0.5-0.6-1-1.2-1.7-1.7c-0.7-0.5-1.6-1-2.6-1.6c-1.1-0.6-2-1.2-2.8-1.7c-1.6-1-2.9-2.2-3.7-3.5
                        c-0.9-1.3-1.3-2.9-1.3-4.9c0-3,1-5.3,2.9-7c1.9-1.7,4.7-2.5,8.3-2.5c2.5,0,5,0.3,7.3,1v5.6H366.4z"/>
                    <path class="elvst0" d="M406.1,230.6h-11.9v7.6h10v5.7h-10v8.9h12.5v5.7h-19.5v-33.7h18.9V230.6z"/>
                    <path class="elvst0" d="M450.3,258.5h-7.7l-5.8-12.7h-4.3v12.7h-6.8v-33.7h10.8c1.7,0,3.2,0.1,4.6,0.3c1.4,0.2,2.7,0.7,3.9,1.6
                        c2.5,1.7,3.8,4.5,3.8,8.3c0,3.8-1.1,6.5-3.4,8.3c-0.7,0.5-1.4,0.9-2.1,1.3L450.3,258.5z M436.4,240.2c1.7,0,3-0.3,3.7-0.8
                        c1-0.8,1.5-2.2,1.5-4.2c0-1.8-0.5-3.1-1.5-3.9c-0.8-0.6-1.9-0.9-3.4-0.9h-4.1v9.8H436.4z"/>
                    <path class="elvst0" d="M479.6,250.3l7.2-25.5h6.3l-10.3,33.7h-7.4l-10.1-33.7h7.5L479.6,250.3z"/>
                    <path class="elvst0" d="M517.6,258.5h-7.1v-33.7h7.1V258.5z"/>
                    <path class="elvst0" d="M551.9,258.8c-5,0-8.8-1.5-11.4-4.6c-2.6-3-3.8-7.2-3.8-12.6c0-5.4,1.3-9.6,3.9-12.6c2.6-3,6.4-4.5,11.3-4.5
                        c2.4,0,4.5,0.3,6.4,0.9v5.7h-0.4c-1.7-0.7-3.4-1-5.2-1c-2.7,0-4.8,0.9-6.3,2.8c-1.5,1.9-2.3,4.7-2.3,8.6c0,3.9,0.8,6.8,2.3,8.7
                        c1.6,1.9,3.7,2.9,6.4,2.9c1.8,0,3.6-0.3,5.2-1h0.4v5.7C556.4,258.5,554.2,258.8,551.9,258.8z"/>
                    <path class="elvst0" d="M596.2,230.6h-11.9v7.6h10v5.7h-10v8.9h12.5v5.7h-19.5v-33.7h18.9V230.6z"/>
                    <path class="elvst0" d="M632.8,231.2c-2.4-0.8-4.5-1.2-6.4-1.2c-1.5,0-2.7,0.3-3.4,0.9c-0.8,0.6-1.1,1.4-1.1,2.4c0,1,0.4,1.8,1.2,2.6
                        c0.8,0.7,2.1,1.5,3.8,2.5c1.1,0.6,2.2,1.3,3.2,2c1.1,0.7,2.1,1.8,3.1,3.2c1,1.4,1.5,3.2,1.5,5.4c0,3.3-1.1,5.7-3.3,7.5
                        c-2.2,1.7-5.2,2.6-8.9,2.6c-2.8,0-5.4-0.4-7.6-1.3v-5.8h0.3c2.7,1,5,1.6,7,1.6c1.6,0,2.9-0.4,3.8-1.1c0.9-0.7,1.4-1.6,1.4-2.8
                        c0-0.8-0.2-1.5-0.7-2.1c-0.5-0.6-1-1.2-1.7-1.7c-0.7-0.5-1.6-1-2.6-1.6c-1.1-0.6-2-1.2-2.8-1.7c-1.6-1-2.9-2.2-3.7-3.5
                        c-0.9-1.3-1.3-2.9-1.3-4.9c0-3,1-5.3,2.9-7c1.9-1.7,4.7-2.5,8.3-2.5c2.5,0,5,0.3,7.3,1v5.6H632.8z"/>
                </g>
            </g>
        </svg>
    }
    @else {
        <svg
            [matTooltip]="collapsed ? 'Operated by Dynatrace ACE Services' : null"
            version="1.1"
            viewBox="0 0 298.32123 258.19052"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            style="width: 100%; padding: 4px"
        >
            <style>
                .st0{fill:#FFFFFF;}
                .st1{fill:#1496FF;}
                .st2{fill:#1284EA;}
                .st3{fill:#BEDF2A;}
                .st4{fill:#6F2DA8;}
                .st5{fill:#591F91;}
                .st6{fill:#73BE28;}
                .st7{fill:#1A1A1A;}
                .st8{fill:url(#SVGID_1_);}
                .st9{fill:url(#SVGID_2_);}
                .st10{fill:url(#SVGID_3_);}
                .st11{fill:url(#SVGID_4_);}
                .st12{fill:url(#SVGID_5_);}
                .st13{fill:url(#SVGID_6_);}
                .st14{fill:url(#SVGID_7_);}
                .st15{fill:url(#SVGID_8_);}
                .st16{fill:url(#SVGID_9_);}
                .st17{fill:url(#SVGID_10_);}
                .st18{fill:url(#SVGID_11_);}
                .st19{fill:url(#SVGID_12_);}
                .st20{fill:url(#SVGID_13_);}
                .st21{fill:url(#SVGID_14_);}
                .st22{fill:url(#SVGID_15_);}
                .st23{fill:url(#SVGID_16_);}
                .st24{fill:url(#SVGID_17_);}
                .st25{fill:url(#SVGID_18_);}
                .st26{fill:url(#SVGID_19_);}
                .st27{fill:url(#SVGID_20_);}
                .st28{fill:url(#SVGID_21_);}
                .st29{fill:url(#SVGID_22_);}
                .st30{fill:url(#SVGID_23_);}
                .st31{fill:url(#SVGID_24_);}
                .st32{fill:url(#SVGID_25_);}
                .st33{fill:url(#SVGID_26_);}
                .st34{fill:url(#SVGID_27_);}
                .st35{fill:url(#SVGID_28_);}
                .st36{fill:url(#SVGID_29_);}
                .st37{fill:url(#SVGID_30_);}
                .st38{fill:url(#SVGID_31_);}
                .st39{fill:url(#SVGID_32_);}
            </style>
            <g transform="translate(-0.31274465,-0.9)">
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0.54710001" y1="129.9933" x2="298.6438"
                    y2="129.9933">
                    <stop offset="0" style="stop-color:#6C31AB" />
                    <stop offset="0.3485" style="stop-color:#6F2DA8" />
                    <stop offset="1" style="stop-color:#1496FF" />
                </linearGradient>
                <path class="st8"
                    d="M 295.4,224.8 174,15 C 168.6,5.6 159.1,0.9 149.6,0.9 c -9.5,0 -19,4.7 -24.4,14.1 L 3.7,224.8 C -8,245 14.8,267.7 35,255.8 l 114.6,-67.6 114.6,67.6 c 20.2,11.9 43,-10.8 31.2,-31 z"
                    id="path12" style="fill:url(#SVGID_1_)" />
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="97.341103" y1="62.883202" x2="325.92969"
                    y2="194.8589">
                    <stop offset="0" style="stop-color:#1496FF;stop-opacity:0" />
                    <stop offset="1" style="stop-color:#1496FF" />
                </linearGradient>
                <path class="st9"
                    d="M 295.4,224.8 174,15 C 168.6,5.6 159.1,0.9 149.6,0.9 c -9.5,0 -19,4.7 -24.4,14.1 L 56.7,133.4 264.2,255.8 c 20.2,11.9 43,-10.8 31.2,-31 z"
                    id="path19" style="fill:url(#SVGID_2_)" />

                <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-12662.744" y1="62.883202" x2="-12434.155"
                    y2="194.8589" gradientTransform="matrix(-1,0,0,1,-12460.892,0)">
                    <stop offset="0" style="stop-color:#6F2DA8;stop-opacity:0" />
                    <stop offset="1" style="stop-color:#6F2DA8" />
                </linearGradient>
                <path class="st10"
                    d="M 3.7,224.8 125.2,15 c 5.4,-9.4 14.9,-14.1 24.4,-14.1 9.5,0 19,4.7 24.4,14.1 L 242.5,133.4 35,255.8 C 14.8,267.7 -8,245 3.7,224.8 Z"
                    id="path26" style="fill:url(#SVGID_3_)" />
                <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="149.59579" y1="196.2121" x2="0.36199999"
                    y2="196.2121">
                    <stop offset="0" style="stop-color:#000000;stop-opacity:0.6" />
                    <stop offset="4.552233e-02" style="stop-color:#09040E;stop-opacity:0.5727" />
                    <stop offset="0.2112" style="stop-color:#28103D;stop-opacity:0.4733" />
                    <stop offset="0.3771" style="stop-color:#421B63;stop-opacity:0.3738" />
                    <stop offset="0.5403" style="stop-color:#552381;stop-opacity:0.2758" />
                    <stop offset="0.7002" style="stop-color:#642897;stop-opacity:0.1799" />
                    <stop offset="0.8553" style="stop-color:#6C2CA4;stop-opacity:8.684903e-02" />
                    <stop offset="1" style="stop-color:#6F2DA8;stop-opacity:0" />
                </linearGradient>
                <path class="st11" d="M 149.6,188.2 34.8,255.8 C 14.6,267.7 -8.2,245 3.5,224.8 l 53.1,-91.4 z" id="path45"
                    style="fill:url(#SVGID_4_)" />
            </g>
        </svg>

    }
</div>
