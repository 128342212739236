import { ActiveProjectService } from 'src/app/services/active-project.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { BaseMenuItem } from 'src/app/types/popups';

export const PersonaMenus: {
    id: string,
    label: string,
    children: BaseMenuItem<{
        user: UserService,
        project: ActiveProjectService,
        tenant: TenantService
    }>[]
}[] = [
    {
        id: "AIAR",
        label: "Systems Architect",
        children: [
            {
                icon: "cloud",
                label: "Enterprise Infrastructure",
                link: "#/navigator?$scope=provider"
            },
            {
                icon: "apps",
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            },
            {
                icon: "grid_view",
                label: "Enterprise Platforms",
                link: "#/navigator?$scope=platform"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            }
        ]
    },
    {
        id: "APPM",
        label: "Application Team Member",
        children: [
            {
                icon: "apps",
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "fact_check",
                label: "My Assigned Work",
                linkTemplate: ctx => `#/navigator?$scope=user.${ctx.user.id}&maximized=true`
            },
            {
                icon: "dvr",
                label: "Problem Analysis",
                link: "#/problem-analysis"
            },
            {
                icon: "cloud",
                label: "Enterprise Infrastructure",
                link: "#/navigator?$scope=provider"
            }
        ]
    },
    {
        id: "BAOS",
        label: "Business Application Owner/Sponsor",
        children: [
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "apps",
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            },
            {
                icon: "dvr",
                label: "Problem Analysis",
                link: "#/problem-analysis"
            },
            {
                icon: "cloud",
                label: "Enterprise Infrastructure",
                link: "#/navigator?$scope=provider"
            }
        ]
    },
    {
        id: "DSTM",
        label: "Digital Security Team Member",
        children: [
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "apps",
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            },
            {
                icon: "dvr",
                label: "Problem Analysis",
                link: "#/problem-analysis"
            },
            {
                icon: "cloud",
                label: "Enterprise Infrastructure",
                link: "#/navigator?$scope=provider"
            }
        ]
    },
    {
        id: "DTAE",
        label: "Dynatrace Account Executive",
        children: [
            {
                icon: "work_outline",
                label: "Accounts",
                link: "#/accounts"
            },
            {
                icon: "analytics",
                label: "License Consumption",
                link: "#/licensereport"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "support",
                label: "Support Tickets",
                link: "#/SupportReport"
            },
            {
                icon: "assignment_ind",
                label: "Service Report",
                link: "#/ServiceReport"
            }
        ]
    },
    {
        id: "DTCS",
        label: "Dynatrace Customer Success Manager",
        children: [
            {
                icon: "work_outline",
                label: "Accounts",
                link: "#/accounts"
            },
            {
                icon: "support",
                label: "Support Tickets",
                link: "#/SupportReport"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "fact_check",
                label: "My Assigned Work",
                linkTemplate: ctx => `#/navigator?$scope=user.${ctx.user.id}&maximized=true`
            },
            {
                icon: "analytics",
                label: "License Consumption",
                link: "#/licensereport"
            }
        ]
    },
    {
        id: "DTEX",
        label: "Dynatrace Executive",
        children: [
            {
                icon: "assignment_ind",
                label: "Service Report",
                link: "#/ServiceReport"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "analytics",
                label: "License Consumption",
                link: "#/licensereport"
            },
            {
                icon: "support",
                label: "Support Tickets",
                link: "#/SupportReport"
            }
        ]
    },
    {
        id: "DTLP",
        label: "Dynatrace License Consumption Purchaser",
        children: [
            {
                icon: "analytics",
                label: "License Consumption",
                link: "#/licensereport"
            },
            {
                icon: "assignment_ind",
                label: "Service Report",
                link: "#/ServiceReport"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            }
        ]
    },
    {
        id: "DTSA",
        label: "Dynatrace Service Associate",
        children: [
            {
                icon: "fact_check",
                label: "My Assigned Work",
                linkTemplate: ctx => `#/navigator?$scope=user.${ctx.user.id}&maximized=true`
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "display_settings",
                label: "Tenant Settings",
                link: "#/TenantPreferences",
                isVisible: ctx => ctx.user.groups.some(g => ["DYNATRACE_GRP_TENANT_OWNERS","ES-ROLE-ELEVATE_ADMIN"].includes(g))
            },
            {
                icon: "work_outline",
                label: "Accounts",
                link: "#/accounts"
            },
            {
                icon: "book",
                label: "Customer Project Library",
                linkTarget: "_blank",
                linkTemplate: ctx => `https://dynatrace.sharepoint.com/sites/ACE_Services/CPL/Lists/Customers%20Projects%20List/All%20%20Geos.aspx?useFiltersInViewXml=1&FilterField1=LinkTitle&FilterValue1=${ctx.tenant.value['original_account_name'] || ctx.tenant.value.account_name}&FilterType1=Computed&FilterOp1=In`,
                isVisible: ctx => ctx.user.groups.some(g => ["ES-ROLE-REGIONAL_MANAGER", "ES-ROLE-ELEVATE_ADMIN", "DYNATRACE_GRP_TENANT_OWNERS"].includes(g))
            }
        ]
    },
    {
        id: "DTSE",
        label: "Dynatrace Sales Engineer",
        children: [
            {
                icon: "work_outline",
                label: "Accounts",
                link: "#/accounts"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "cloud",
                label: "Enterprise Infrastructure",
                link: "#/navigator?$scope=provider"
            },
            {
                icon: "apps",
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            },
            {
                icon: "analytics",
                label: "License Consumption",
                link: "#/licensereport"
            }
        ]
    },
    {
        id: "DTSM",
        label: "Dynatrace Service Manager",
        children: [
            {
                icon: "work_outline",
                label: "Accounts",
                link: "#/accounts"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "person_add",
                label: "Users",
                link: "#/navigator?$scope=user"
            },
            {
                icon: "manage_history",
                label: "Flexpoint Management",
                link: "#/flexpoint"
            },
            {
                icon: "display_settings",
                label: "Tenant Settings",
                link: "#/TenantPreferences",
                isVisible: ctx => ctx.user.groups.some(g => ["DYNATRACE_GRP_TENANT_OWNERS","ES-ROLE-ELEVATE_ADMIN"].includes(g))
            }
        ]
    },
    {
        id: "DTSP",
        label: "Dynatrace Support Associate",
        children: [
            {
                icon: "support",
                label: "Support Tickets",
                link: "#/SupportReport"
            },
            {
                icon: "work_outline",
                label: "Accounts",
                link: "#/accounts"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            }
        ]
    },
    {
        id: "EOTM",
        label: "Enterprise Observability Team Member",
        children: [
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "analytics",
                label: "License Consumption",
                link: "#/licensereport"
            },
            {
                icon: "support",
                label: "Support Tickets",
                link: "#/SupportReport"
            },
            {
                icon: "fact_check",
                label: "My Assigned Work",
                linkTemplate: ctx => `#/navigator?$scope=user.${ctx.user.id}&maximized=true`
            },
            {
                icon: "apps",
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            }
        ]
    },
    {
        id: "OPTM",
        label: "Operations Team Member",
        children: [
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "apps",
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            },
            {
                icon: "dvr",
                label: "Problem Analysis",
                link: "#/problem-analysis"
            },
            {
                icon: "grid_view",
                label: "Enterprise Platforms",
                link: "#/navigator?$scope=platform"
            },
            {
                icon: "cloud",
                label: "Enterprise Infrastructure",
                link: "#/navigator?$scope=provider"
            }
        ]
    },
    {
        id: "OTHR",
        label: "Alternate Persona",
        children: [
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "apps",
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            },
            {
                icon: "fact_check",
                label: "My Assigned Work",
                linkTemplate: ctx => `#/navigator?$scope=user.${ctx.user.id}&maximized=true`
            }
        ]
    },
    {
        id: "PLTM",
        label: "Platform Team Member",
        children: [
            {
                icon: "grid_view",
                label: "Enterprise Platforms",
                link: "#/navigator?$scope=platform"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "fact_check",
                label: "My Assigned Work",
                linkTemplate: ctx => `#/navigator?$scope=user.${ctx.user.id}&maximized=true`
            },
            {
                icon: "dvr",
                label: "Problem Analysis",
                link: "#/problem-analysis"
            },
            {
                icon: "cloud",
                label: "Enterprise Infrastructure",
                link: "#/navigator?$scope=provider"
            }
        ]
    },
    {
        id: "PORT",
        label: "Application Portfolio Manager",
        children: [
            {
                icon: "apps",
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "grid_view",
                label: "Enterprise Platforms",
                link: "#/navigator?$scope=platform"
            },
            {
                icon: "cloud",
                label: "Enterprise Infrastructure",
                link: "#/navigator?$scope=provider"
            }
        ]
    },
    {
        id: "PROJ",
        label: "Project Manager",
        children: [
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "fact_check",
                label: "My Assigned Work",
                linkTemplate: ctx => `#/navigator?$scope=user.${ctx.user.id}&maximized=true`
            },
            {
                icon: "apps",
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            },
            {
                icon: "drive_folder_upload",
                label: "Project Import",
                link: "#/Power-Import-Wizard-eob?mode=eob"
            }
        ]
    },
    {
        id: "QAPF",
        label: "QA / Performance Team Member",
        children: [
            {
                icon: "apps",
                label: "Enterprise Applications",
                link: "#/navigator?$scope=application"
            },
            {
                icon: "source",
                label: "Projects",
                link: "#/navigator?$scope=project"
            },
            {
                icon: "fact_check",
                label: "My Assigned Work",
                linkTemplate: ctx => `#/navigator?$scope=user.${ctx.user.id}&maximized=true`
            },
            {
                icon: "dvr",
                label: "Problem Analysis",
                link: "#/problem-analysis"
            },
            {
                icon: "cloud",
                label: "Enterprise Infrastructure",
                link: "#/navigator?$scope=provider"
            }
        ]
    }
]
